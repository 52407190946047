import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import { Typography, Box } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import SearchAndScan from './SearchAndScan'; // Import คอมโพเนนต์ SearchAndScan

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  cursor: 'pointer',
}));

export default function NoCheckAssets() {
  const [assets, setAssets] = useState([]);
  const [filteredAssets, setFilteredAssets] = useState([]); // State สำหรับเก็บข้อมูลที่ถูกกรอง
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://dbaac.dodaydream.net/api/fixed_assets/all');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();

        const filteredData = data.filter(asset => asset.Check_By_AC === "No");
        setAssets(filteredData);
        setFilteredAssets(filteredData); // เริ่มต้นให้ filteredAssets เท่ากับ assets ที่ถูกกรองแล้ว
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSearch = (searchTerm) => {
    const filtered = assets.filter(asset =>
      asset.Inventory_ID ? asset.Inventory_ID.toLowerCase().includes(searchTerm.toLowerCase()) : false
    );
    setFilteredAssets(filtered);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (id) => {
    navigate(`/asset/${id}`);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const displayedAssets = filteredAssets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Paper>
      <Box display="flex" alignItems="center" justifyContent="space-between" p={2}>
        <Typography variant="h6">No Check Assets</Typography>
        <Box display="flex" alignItems="center">
          <WarningIcon color="error" />
          <Typography variant="body1" style={{ marginLeft: '8px' }}>
            {/* นับจำนวนจาก filteredAssets แทน assets */}
            Total No Check: {filteredAssets.length}
          </Typography>
        </Box>
      </Box>

      <SearchAndScan onSearch={handleSearch} /> {/* เพิ่มคอมโพเนนต์ SearchAndScan */}

      <TableContainer>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Inventory ID</StyledTableCell>
              <StyledTableCell>Company</StyledTableCell>
              <StyledTableCell>Site</StyledTableCell>
              <StyledTableCell>Department Responsible</StyledTableCell>
              <StyledTableCell>แผนก</StyledTableCell>
              <StyledTableCell>Employee Responsible</StyledTableCell>
              <StyledTableCell>Fixed Asset Class</StyledTableCell>
              <StyledTableCell>Fixed Asset ID</StyledTableCell>
              <StyledTableCell>ClassName</StyledTableCell>
              <StyledTableCell>Asset Name</StyledTableCell>
              <StyledTableCell>Note</StyledTableCell>
              <StyledTableCell>Note 1</StyledTableCell>
              <StyledTableCell>Picture 1</StyledTableCell>
              <StyledTableCell>Picture 2</StyledTableCell>
              <StyledTableCell>Picture 11</StyledTableCell>
              <StyledTableCell>Picture 21</StyledTableCell>
              <StyledTableCell>Qty</StyledTableCell>
              <StyledTableCell>Serial No</StyledTableCell>
              <StyledTableCell>STATUS</StyledTableCell>
              <StyledTableCell>Status Tag</StyledTableCell>
              <StyledTableCell>Sub Location</StyledTableCell>
              <StyledTableCell>Sub Loc Name</StyledTableCell>
              <StyledTableCell>Check By AC</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedAssets.map((asset) => (
              <StyledTableRow key={asset.id} onClick={() => handleRowClick(asset.id)}>
                <StyledTableCell>{asset.Inventory_ID || '-'}</StyledTableCell>
                <StyledTableCell>{asset.Company || '-'}</StyledTableCell>
                <StyledTableCell>{asset.Site || '-'}</StyledTableCell>
                <StyledTableCell>{asset.Department_Responsible || '-'}</StyledTableCell>
                <StyledTableCell>{asset.Departmentname || '-'}</StyledTableCell>
                <StyledTableCell>{asset.Employee_Responsible || '-'}</StyledTableCell>
                <StyledTableCell>{asset.Fixed_Asset_Class || '-'}</StyledTableCell>
                <StyledTableCell>{parseInt(asset.Fixed_Asset_ID, 10)}</StyledTableCell> {/* แปลง Fixed Asset ID ให้เป็นจำนวนเต็ม */}
                <StyledTableCell>{asset.Fixed_asset_classname || '-'}</StyledTableCell>
                <StyledTableCell>{asset.Assetname || '-'}</StyledTableCell>
                <StyledTableCell>{asset.Note || '-'}</StyledTableCell>
                <StyledTableCell>{asset.Note1 || '-'}</StyledTableCell>
                <StyledTableCell>{asset.Picture_1 || '-'}</StyledTableCell>
                <StyledTableCell>{asset.Picture_2 || '-'}</StyledTableCell>
                <StyledTableCell>{asset.Picture_11 || '-'}</StyledTableCell>
                <StyledTableCell>{asset.Picture_21 || '-'}</StyledTableCell>
                <StyledTableCell>{asset.Qty || '-'}</StyledTableCell>
                <StyledTableCell>{asset.Serial_No || '-'}</StyledTableCell>
                <StyledTableCell>{asset.STATUS || '-'}</StyledTableCell>
                <StyledTableCell>{asset.Status_Tag || '-'}</StyledTableCell>
                <StyledTableCell>{asset.Sub_Location || '-'}</StyledTableCell>
                <StyledTableCell>{asset.Sub_Loc_Name || '-'}</StyledTableCell>
                <StyledTableCell>{asset.Check_By_AC || '-'}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={filteredAssets.length} // ใช้จำนวนของ filteredAssets แทน assets ทั้งหมด
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[50]}
      />
    </Paper>
  );
}
