import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

const SearchAndScan = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [scannerWindow, setScannerWindow] = useState(null);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    onSearch(value);
  };

  const handleSearch = () => {
    onSearch(searchTerm);
  };

  const openQRScanner = () => {
    const newWindow = window.open('', 'QRScanner', 'width=600,height=600');
    newWindow.document.write(`
      <html>
        <head>
          <title>QR Scanner</title>
          <script src="https://unpkg.com/html5-qrcode"></script>
          <style>
            body { margin: 0; padding: 20px; display: flex; flex-direction: column; align-items: center; justify-content: center; height: 100vh; font-family: Arial, sans-serif; }
            #qr-reader { width: 100%; max-width: 900px; }
            #close-button, #request-permission { margin-top: 20px; padding: 10px 20px; font-size: 16px; }
            #status { margin-top: 20px; color: #666; }
          </style>
        </head>
        <body>
          <div id="qr-reader"></div>
          <div id="status"></div>
          <button id="request-permission">Request Camera Permission</button>
          <button id="close-button">Close Scanner</button>
          <script>
            const html5QrCode = new Html5Qrcode("qr-reader");
            const statusDiv = document.getElementById('status');
            const requestPermissionButton = document.getElementById('request-permission');

            const qrCodeSuccessCallback = (decodedText, decodedResult) => {
              window.opener.postMessage({ type: 'qr-result', result: decodedText }, '*');
              html5QrCode.stop();
              window.close();
            };

            const startScanner = () => {
              const config = { fps: 10, qrbox: { width: 250, height: 250 } };
              html5QrCode.start({ facingMode: "environment" }, config, qrCodeSuccessCallback)
                .then(() => {
                  statusDiv.textContent = 'Scanner started successfully';
                  requestPermissionButton.style.display = 'none';
                })
                .catch((err) => {
                  statusDiv.textContent = 'Error starting scanner: ' + err;
                  requestPermissionButton.style.display = 'block';
                });
            };

            requestPermissionButton.addEventListener('click', () => {
              navigator.mediaDevices.getUserMedia({ video: true })
                .then((stream) => {
                  stream.getTracks().forEach(track => track.stop());
                  startScanner();
                })
                .catch((err) => {
                  statusDiv.textContent = 'Error accessing camera: ' + err;
                });
            });

            document.getElementById('close-button').addEventListener('click', () => {
              html5QrCode.stop().then(() => {
                window.close();
              }).catch((err) => {
                console.error('Error stopping scanner:', err);
                window.close();
              });
            });

            // Try to start scanner immediately
            startScanner();
          </script>
        </body>
      </html>
    `);
    newWindow.document.close();
    setScannerWindow(newWindow);
  };

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.type === 'qr-result') {
        setSearchTerm(event.data.result);
        onSearch(event.data.result);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
      if (scannerWindow) {
        scannerWindow.close();
      }
    };
  }, [onSearch]);

  return (
    <Grid container spacing={2} alignItems="center" style={{ marginBottom: '16px' }}>
      <Grid item xs={8} sm={10}>
        <TextField
          fullWidth
          placeholder="กรอก Inventory ID หรือสแกน QR Code"
          value={searchTerm}
          onChange={handleInputChange}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSearch();
            }
          }}
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleSearch}>
                <SearchIcon />
              </IconButton>
            ),
          }}
        />
      </Grid>
      <Grid item xs={4} sm={2}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<QrCodeScannerIcon />}
          onClick={openQRScanner}
          fullWidth
        >
          สแกน QR
        </Button>
      </Grid>
    </Grid>
  );
};

export default SearchAndScan;