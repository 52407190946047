import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Paper, Typography, TextField, Button, Grid, Select, MenuItem, InputLabel, FormControl, Alert, Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useSelector } from 'react-redux';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Autocomplete from '@mui/material/Autocomplete';

const EditAsset = () => {
  const { id } = useParams();
  const [asset, setAsset] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [imageFiles, setImageFiles] = useState({});
  const [cameraOpen, setCameraOpen] = useState(false);
  const [currentImageField, setCurrentImageField] = useState('');
  const [previewOpen, setPreviewOpen] = useState(false);
  const [imagePreviewOpen, setImagePreviewOpen] = useState(false);
  const [previewImageUrl, setPreviewImageUrl] = useState('');
  const [uploadedImages, setUploadedImages] = useState({});
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    const fetchAssetDetail = async () => {
      try {
        const response = await fetch(`https://dbaac.dodaydream.net/api/fixed_assets/${id}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setAsset(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchEmployees = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch('https://dbaac.dodaydream.net/api/employees', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch employees');
        }
        const data = await response.json();
        if (Array.isArray(data)) {
          setEmployees(data);
        }
      } catch (error) {
        console.error('Error fetching employees:', error);
      }
    };

    fetchAssetDetail();
    fetchEmployees();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAsset({ ...asset, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    setImageFiles({ ...imageFiles, [name]: file });
    setUploadedImages({ ...uploadedImages, [name]: URL.createObjectURL(file) });
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('User is not authenticated.');

      const formData = new FormData();
      formData.append('Audit_By', user.employee_id || '');

      Object.keys(asset).forEach((key) => {
        if (key !== 'last_edit') {
          formData.append(key, asset[key] || '');
        }
      });

      Object.keys(imageFiles).forEach((key) => {
        formData.append(key, imageFiles[key]);
      });

      const response = await fetch(`https://dbaac.dodaydream.net/api/fixed_assets/${id}`, {
        method: 'PUT',
        headers: {
          'x-access-token': token,
        },
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update asset');
      }

      setSuccess('Asset updated successfully');
      setTimeout(() => navigate(`/asset/${id}`), 1500);
    } catch (error) {
      setError(error.message);
    }
  };

  const openCameraDialog = (imageField) => {
    setCurrentImageField(imageField);
    setCameraOpen(true);
  };

  const handleCameraCapture = (e) => {
    const file = e.target.files[0];
    setImageFiles({ ...imageFiles, [currentImageField]: file });
    setUploadedImages({ ...uploadedImages, [currentImageField]: URL.createObjectURL(file) });
    setCameraOpen(false);
  };

  const handleOpenPreview = () => {
    setPreviewOpen(true);
  };

  const handleClosePreview = () => {
    setPreviewOpen(false);
  };

  const handleOpenImagePreview = (imageUrl) => {
    setPreviewImageUrl(imageUrl);
    setImagePreviewOpen(true);
  };

  const handleCloseImagePreview = () => {
    setImagePreviewOpen(false);
    setPreviewImageUrl('');
  };

  // Read-only image render for Picture 1 and Picture 2
  const renderImageReadOnly = (label, fieldName) => {
    const imageUrl = asset[fieldName] ? `https://dbaac.dodaydream.net/images/assets/${asset[fieldName]}` : 'https://dbaac.dodaydream.net/images/placeholder.png';
    return (
      <Box mt={2} key={fieldName}>
        <InputLabel>{`${label}`}</InputLabel>
        <Box display="flex" gap={2} alignItems="center">
          <img
            src={imageUrl}
            alt={label}
            style={{ width: '150px', height: '150px', objectFit: 'cover', marginRight: '16px', cursor: 'pointer' }}
            onClick={() => handleOpenImagePreview(imageUrl)}
          />
        </Box>
      </Box>
    );
  };

  // Editable image render for other fields
  const renderImage = (label, fieldName) => {
    const imageUrl = uploadedImages[fieldName] || (asset[fieldName] ? `https://dbaac.dodaydream.net/images/assets/${asset[fieldName]}` : 'https://dbaac.dodaydream.net/images/placeholder.png');
    return (
      <Box mt={2} key={fieldName}>
        <InputLabel>{`${label}`}</InputLabel>
        <Box display="flex" gap={2} alignItems="center">
          <img
            src={imageUrl}
            alt={label}
            style={{ width: '150px', height: '150px', objectFit: 'cover', marginRight: '16px', cursor: 'pointer' }}
            onClick={() => handleOpenImagePreview(imageUrl)}
          />
          <input
            accept="image/*"
            id={`upload-${fieldName}`}
            name={fieldName}
            type="file"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <label htmlFor={`upload-${fieldName}`}>
            <Button
              variant="contained"
              color="primary"
              component="span"
              startIcon={<CloudUploadIcon />}
            >
              Upload
            </Button>
          </label>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<CameraAltIcon />}
            onClick={() => openCameraDialog(fieldName)}
          >
            Capture
          </Button>
        </Box>
      </Box>
    );
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Paper style={{ padding: '16px', margin: '16px' }}>
      <Typography variant="h4" gutterBottom>Edit Asset</Typography>
      
      {success && <Alert severity="success">{success}</Alert>}

      {asset && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label="ID"
              value={asset.id}
              InputProps={{ readOnly: true }}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Inventory ID"
              value={asset.Inventory_ID}
              InputProps={{ readOnly: true }}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Company"
              name="Company"
              value={asset.Company || ''}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Site"
              name="Site"
              value={asset.Site || ''}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Department Responsible"
              name="Department_Responsible"
              value={asset.Department_Responsible || ''}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Department Name"
              name="Departmentname"
              value={asset.Departmentname || ''}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
                          label="Employee Responsible"
                          name="Employee_Responsible"
                          value={asset.Employee_Responsible || ''}
                          onChange={handleInputChange}
                          fullWidth
                          margin="normal"
                        />
                        <TextField
                          label="Fixed Asset Class"
                          name="Fixed_Asset_Class"
                          value={asset.Fixed_Asset_Class || ''}
                          onChange={handleInputChange}
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Fixed Asset ID"
                          name="Fixed_Asset_ID"
                          value={parseInt(asset.Fixed_Asset_ID, 10) || ''}
                          onChange={handleInputChange}
                          fullWidth
                          margin="normal"
                        />
                        <TextField
                          label="Class Name"
                          name="Fixed_asset_classname"
                          value={asset.Fixed_asset_classname || ''}
                          onChange={handleInputChange}
                          fullWidth
                          margin="normal"
                        />
                        <TextField
                          label="Asset Name"
                          name="Assetname"
                          value={asset.Assetname || ''}
                          onChange={handleInputChange}
                          fullWidth
                          margin="normal"
                        />
                        <TextField
                          label="Qty"
                          name="Qty"
                          value={asset.Qty || ''}
                          onChange={handleInputChange}
                          fullWidth
                          margin="normal"
                        />
                        <FormControl fullWidth margin="normal">
                          <InputLabel>Check By AC</InputLabel>
                          <Select
                            label="Check By AC"
                            name="Check_By_AC"
                            value={asset.Check_By_AC || ''}
                            onChange={handleInputChange}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                          <InputLabel>Status Tag</InputLabel>
                          <Select
                            label="Status Tag"
                            name="Status_Tag"
                            value={asset.Status_Tag || ''}
                            onChange={handleInputChange}
                          >
                            <MenuItem value="ปกติ">ปกติ</MenuItem>
                            <MenuItem value="ชำรุด">ชำรุด</MenuItem>
                            <MenuItem value="สูญหาย">สูญหาย</MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                          <InputLabel>STATUS</InputLabel>
                          <Select
                            label="STATUS"
                            name="STATUS"
                            value={asset.STATUS || ''}
                            onChange={handleInputChange}
                          >
                            <MenuItem value="ใช้งานปกติ">ใช้งานปกติ</MenuItem>
                            <MenuItem value="ไม่ได้ใช้งาน">ไม่ได้ใช้งาน</MenuItem>
                            <MenuItem value="ชำรุด/เสื่อมสภาพ">ชำรุด/เสื่อมสภาพ</MenuItem>
                            <MenuItem value="สินทรัพย์รอการขาย">สินทรัพย์รอการขาย</MenuItem>
                            <MenuItem value="ไม่พบทรัพย์สิน">ไม่พบทรัพย์สิน</MenuItem>
                          </Select>
                        </FormControl>
                        <TextField
                          label="Note"
                          name="Note"
                          value={asset.Note || ''}
                          onChange={handleInputChange}
                          fullWidth
                          margin="normal"
                        />
                        <TextField
                          label="Note 1"
                          name="Note1"
                          value={asset.Note1 || ''}
                          onChange={handleInputChange}
                          fullWidth
                          margin="normal"
                        />
                        <TextField
                          label="Sub Location"
                          name="Sub_Location"
                          value={asset.Sub_Location || ''}
                          onChange={handleInputChange}
                          fullWidth
                          margin="normal"
                        />
                        <TextField
                          label="Sub Loc Name"
                          name="Sub_Loc_Name"
                          value={asset.Sub_Loc_Name || ''}
                          onChange={handleInputChange}
                          fullWidth
                          margin="normal"
                        />
            
                        <FormControl fullWidth margin="normal">
                          <InputLabel>Site Check</InputLabel>
                          <Select
                            label="Site Check"
                            name="sitecheck"
                            value={asset.sitecheck || ''}
                            onChange={handleInputChange}
                          >
                            <MenuItem value="1">Yes</MenuItem>
                            <MenuItem value="0">No</MenuItem>
                          </Select>
                        </FormControl>
            
                        <Autocomplete
                          options={employees}
                          getOptionLabel={(option) =>
                            `${option.firstname} ${option.lastname} (${option.firstname_th} ${option.lastname_th})`
                          }
                          value={employees.find((e) => e.id === asset.assign_to) || null}
                          onChange={(event, newValue) => {
                            setAsset({ ...asset, assign_to: newValue ? newValue.id : '' });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Assign To"
                              margin="normal"
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  )}
            
                  {renderImageReadOnly('Picture 1', 'Picture_1')}
                  {renderImageReadOnly('Picture 2', 'Picture_2')}
                  {renderImage('Picture 11', 'Picture_11')}
                  {renderImage('Picture 21', 'Picture_21')}
            
                  <Dialog open={cameraOpen} onClose={() => setCameraOpen(false)}>
                    <DialogTitle>Take a Picture</DialogTitle>
                    <DialogContent>
                      <input
                        accept="image/*"
                        type="file"
                        capture="camera"
                        onChange={handleCameraCapture}
                        style={{ display: 'none' }}
                      />
                      <Button variant="contained" color="primary" component="label">
                        Capture Image
                        <input type="file" accept="image/*" capture="environment" style={{ display: 'none' }} onChange={handleCameraCapture} />
                      </Button>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setCameraOpen(false)} color="primary">
                        Cancel
                      </Button>
                    </DialogActions>
                  </Dialog>
            
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleOpenPreview}
                    style={{ marginTop: '16px' }}
                  >
                    Preview Changes
                  </Button>
            
                  <Dialog open={previewOpen} onClose={handleClosePreview}>
                    <DialogTitle>Preview Changes</DialogTitle>
                    <DialogContent>
                      <Typography>Company: {asset.Company}</Typography>
                      <Typography>Company Name: {asset.CompanyName}</Typography>
                      <Typography>Capitalization Date: {asset.Capitalization_Date}</Typography>
                      <Typography>Fixed Asset Life Cycle Status: {asset.Fixed_Asset_Life_Cycle_Status}</Typography>
                      <Typography>Fixed Asset Class: {asset.Fixed_Asset_Class}</Typography>
                      <Typography>Fixed Asset Class Name: {asset.Fixed_asset_classname}</Typography>
                      <Typography>Fixed Asset ID: {asset.Fixed_Asset_ID}</Typography>
                      <Typography>Individual Material: {asset.Individual_Material}</Typography>
                      <Typography>Asset Name: {asset.Assetname}</Typography>
                      <Typography>Inventory ID: {asset.Inventory_ID}</Typography>
                      <Typography>Inventory ID OLD: {asset.Inventory_ID_OLD}</Typography>
                      <Typography>Employee Responsible: {asset.Employee_Responsible}</Typography>
                      <Typography>Employee Name: {asset.Employee_Name}</Typography>
                      <Typography>Department Responsible: {asset.Department_Responsible}</Typography>
                      <Typography>Site: {asset.Site}</Typography>
                      <Typography>Sub Location: {asset.Sub_Location}</Typography>
                      <Typography>Sub Loc Name: {asset.Sub_Loc_Name}</Typography>
                      <Typography>Serial No: {asset.Serial_No}</Typography>
                      <Typography>Warranty Expiry Date: {asset.Warranty_Expiry_Date}</Typography>
                      <Typography>Supplier: {asset.Supplier}</Typography>
                      <Typography>Note: {asset.Note}</Typography>
                      <Typography>Cost Center: {asset.Cost_Center}</Typography>
                      <Typography>Department Name: {asset.Departmentname}</Typography>
                      <Typography>Useful Life Years: {asset.Useful_Life_Years}</Typography>
                      <Typography>Historical Cost: {asset.Historical_Cost}</Typography>
                      <Typography>Accumulated DP: {asset.Accumulated_DP}</Typography>
                      <Typography>NBV: {asset.NBV}</Typography>
                      <Typography>Picture 1: <img src={uploadedImages.Picture_1 || asset.Picture_1} alt="Picture 1" style={{ width: '50px', cursor: 'pointer' }} onClick={() => handleOpenImagePreview(uploadedImages.Picture_1 || asset.Picture_1)} /></Typography>
                      <Typography>Picture 2: <img src={uploadedImages.Picture_2 || asset.Picture_2} alt="Picture 2" style={{ width: '50px', cursor: 'pointer' }} onClick={() => handleOpenImagePreview(uploadedImages.Picture_2 || asset.Picture_2)} /></Typography>
                      <Typography>
                        Picture 11 (from 2024): {asset.Picture_11 ? (
                          <span>Already uploaded</span>
                        ) : (
                          <span>To be updated with new capture</span>
                        )}
                      </Typography>
            
                      <Typography>
                        Picture 21 (from 2024): {asset.Picture_21 ? (
                          <span>Already uploaded</span>
                        ) : (              <span>To be updated with new capture</span>
                        )}
                      </Typography>
            
                      <Typography>Status: {asset.STATUS}</Typography>
                      <Typography>Status Tag: {asset.Status_Tag}</Typography>
                      <Typography>Check By AC: {asset.Check_By_AC}</Typography>
                      <Typography>Qty: {asset.Qty}</Typography>
                      <Typography>Site Check: {asset.sitecheck === '1' ? 'Yes' : 'No'}</Typography>
                      <Typography>User Check: {asset.usercheck === '1' ? 'Yes' : 'No'}</Typography>
                      <Typography>
                        Assign To: {employees.find(e => e.id === asset.assign_to)?.firstname_th} {employees.find(e => e.id === asset.assign_to)?.lastname_th}
                      </Typography>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClosePreview} color="primary">
                        Close
                      </Button>
                      <Button onClick={handleSave} color="secondary" variant="contained">
                        Save Changes
                      </Button>
                    </DialogActions>
                  </Dialog>
            
                  {/* Full-Screen Image Preview Modal */}
                  <Dialog open={imagePreviewOpen} onClose={handleCloseImagePreview}>
                    <DialogContent>
                      <img
                        src={previewImageUrl}
                        alt="Full Preview"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseImagePreview} color="primary">
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
            
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    style={{ marginTop: '16px' }}
                  >
                    Save Changes
                  </Button>
                </Paper>
              );
            };
            
            export default EditAsset;
            
                         
            