import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Paper, Typography, TextField, Button, Grid, MenuItem, Alert, Autocomplete, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

const AddAsset = () => {
  const [asset, setAsset] = useState({
    Company: '',
    CompanyName: '',
    Capitalization_Date: '',
    Fixed_Asset_Life_Cycle_Status: '',
    Fixed_Asset_Class: '',
    Fixed_asset_classname: '',
    Fixed_Asset_ID: '',
    Individual_Material: '',
    Assetname: '',
    Inventory_ID: '', // This field is required
    Inventory_ID_OLD: '',
    Employee_Responsible: '',
    Employee_Name: '',
    Department_Responsible: '',
    Site: '',
    Sub_Location: '',
    Sub_Loc_Name: '',
    Serial_No: '',
    Warranty_Expiry_Date: '',
    Supplier: '',
    Note: '',
    Cost_Center: '',
    Departmentname: '',
    Useful_Life_Years: '',
    Historical_Cost: '',
    Accumulated_DP: '',
    NBV: '',
    Picture_1: '',
    Picture_2: '',
    STATUS: '',
    Status_Tag: '',
    Check_By_AC: '',
    Qty: '',
    usercheck: '',  // Updated for dropdown (Yes/No)
    sitecheck: '',  // Updated for dropdown (Yes/No)
    assign_to: ''  // Foreign key field
  });

  const [employees, setEmployees] = useState([]);
  const [error, setError] = useState('');
  const [previewOpen, setPreviewOpen] = useState(false); // State for the preview dialog
  const navigate = useNavigate();

  // Fetch employees for the assign_to field dropdown
  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const token = localStorage.getItem('token'); // or wherever you store the token
        const response = await fetch('https://dbaac.dodaydream.net/api/employees', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch employees');
        }
        const data = await response.json();
        if (Array.isArray(data)) {
          setEmployees(data);
        } else {
          setEmployees([]); // Fallback if data is not an array
        }
      } catch (error) {
        console.error('Error fetching employees:', error);
      }
    };

    fetchEmployees();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAsset({ ...asset, [name]: value });
  };

  const handlePreview = () => {
    setPreviewOpen(true); // Open the preview dialog
  };

  const handleSave = async () => {
    setError('');

    if (!asset.Inventory_ID) {
      setError('Inventory ID is required');
      return;
    }

    try {
      const response = await fetch('https://dbaac.dodaydream.net/api/fixed_assets', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(asset),
      });
      if (!response.ok) {
        throw new Error('Failed to add asset');
      }
      navigate('/'); // Navigate after success
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred while saving the asset.');
    }
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false); // Close the preview dialog
  };

  return (
    <Paper style={{ padding: '16px', margin: '16px' }}>
      <Typography variant="h4" gutterBottom>
        Add New Asset
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            label="Company"
            name="Company"
            value={asset.Company}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Company Name"
            name="CompanyName"
            value={asset.CompanyName}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Capitalization Date"
            name="Capitalization_Date"
            type="date"
            value={asset.Capitalization_Date}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Fixed Asset Life Cycle Status"
            name="Fixed_Asset_Life_Cycle_Status"
            select
            value={asset.Fixed_Asset_Life_Cycle_Status}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          >
            <MenuItem value="Capitalized">Capitalized</MenuItem>
            <MenuItem value="Disposed">Disposed</MenuItem>
          </TextField>
          <TextField
            label="Fixed Asset Class"
            name="Fixed_Asset_Class"
            value={asset.Fixed_Asset_Class}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Fixed Asset Class Name"
            name="Fixed_asset_classname"
            value={asset.Fixed_asset_classname}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Fixed Asset ID"
            name="Fixed_Asset_ID"
            value={asset.Fixed_Asset_ID}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Individual Material"
            name="Individual_Material"
            value={asset.Individual_Material}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Asset Name"
            name="Assetname"
            value={asset.Assetname}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Inventory ID"
            name="Inventory_ID"
            value={asset.Inventory_ID}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Employee Responsible"
            name="Employee_Responsible"
            value={asset.Employee_Responsible}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Employee Name"
            name="Employee_Name"
            value={asset.Employee_Name}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Department Responsible"
            name="Department_Responsible"
            value={asset.Department_Responsible}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Site"
            name="Site"
            value={asset.Site}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Sub Location"
            name="Sub_Location"
            value={asset.Sub_Location}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Sub Loc Name"
            name="Sub_Loc_Name"
            value={asset.Sub_Loc_Name}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Serial No"
            name="Serial_No"
            value={asset.Serial_No}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Warranty Expiry Date"
            name="Warranty_Expiry_Date"
            type="date"
            value={asset.Warranty_Expiry_Date}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Note"
            name="Note"
            value={asset.Note}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />
          <TextField
            label="Cost Center"
            name="Cost_Center"
            value={asset.Cost_Center}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="STATUS"
            name="STATUS"
            select
            value={asset.STATUS}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          >
            <MenuItem value="ใช้งานปกติ">ใช้งานปกติ</MenuItem>
            <MenuItem value="ไม่ได้ใช้งาน">ไม่ได้ใช้งาน</MenuItem>
            <MenuItem value="ชำรุด/เสื่อมสภาพ">ชำรุด/เสื่อมสภาพ</MenuItem>
            <MenuItem value="สินทรัพย์รอการขาย">สินทรัพย์รอการขาย</MenuItem>
            <MenuItem value="ไม่พบทรัพย์สิน">ไม่พบทรัพย์สิน</MenuItem>
          </TextField>
          <TextField
            label="Status Tag"
            name="Status_Tag"
            select
            value={asset.Status_Tag}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          >
            <MenuItem value="ปกติ">ปกติ</MenuItem>
            <MenuItem value="ชำรุด">ชำรุด</MenuItem>
            <MenuItem value="สูญหาย">สูญหาย</MenuItem>
          </TextField>
          <TextField
            label="Check By AC"
            name="Check_By_AC"
            select
            value={asset.Check_By_AC}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </TextField>
        </Grid>

        {/* New Fields */}
        <Grid item xs={12} md={6}>
          <TextField
            label="Inventory ID OLD"
            name="Inventory_ID_OLD"
            value={asset.Inventory_ID_OLD}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="User Check"
            name="usercheck"
            select
            value={asset.usercheck}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          >
            <MenuItem value=""><em>Select Yes/No</em></MenuItem>
            <MenuItem value="1">Yes</MenuItem>
            <MenuItem value="0">No</MenuItem>
          </TextField>

          {/* Site Check */}
          <TextField
            label="Site Check"
            name="sitecheck"
            select
            value={asset.sitecheck}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          >
            <MenuItem value=""><em>Select Yes/No</em></MenuItem>
            <MenuItem value="1">Yes</MenuItem>
            <MenuItem value="0">No</MenuItem>
          </TextField>
        </Grid>

        {/* Autocomplete for Assign To field */}
        <Grid item xs={12} md={6}>
          <Autocomplete
            options={employees}
            getOptionLabel={(option) => `${option.firstname} ${option.lastname} (${option.firstname_th} ${option.lastname_th})`}
            renderInput={(params) => (
              <TextField {...params} label="Assign To" fullWidth margin="normal" />
            )}
            value={employees.find((employee) => employee.id === asset.assign_to) || null}
            onChange={(event, newValue) => {
              setAsset({ ...asset, assign_to: newValue ? newValue.id : '' });
            }}
          />
        </Grid>
      </Grid>

      <Button
        variant="contained"
        color="primary"
        onClick={handlePreview}
        style={{ marginTop: '16px', marginRight: '16px' }}
      >
        Preview Changes
      </Button>

      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
        style={{ marginTop: '16px' }}
      >
        Save Asset
      </Button>

      {/* Preview Dialog */}
      <Dialog open={previewOpen} onClose={handlePreviewClose}>
        <DialogTitle>Preview Changes</DialogTitle>
        <DialogContent>
          <Typography variant="h6">Company: {asset.Company}</Typography>
          <Typography variant="h6">Site: {asset.Site}</Typography>
          <Typography variant="h6">Department Responsible: {asset.Department_Responsible}</Typography>
          <Typography variant="h6">Employee Responsible: {asset.Employee_Responsible}</Typography>
          <Typography variant="h6">Asset Name: {asset.Assetname}</Typography>
          <Typography variant="h6">Note: {asset.Note}</Typography>
          {/* Add more fields here for preview as needed */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePreviewClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default AddAsset;

